/* eslint-disable no-script-url */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const ApplicationErrorMessage = ({ is404 }) => {
  const linkPath = is404
    ? {
        en: 'javascript:history.go(-1)',
        fr: 'javascript:history.go(-1)',
      }
    : {
        en: '/',
        fr: '/accueil',
      };
  const copy = is404
    ? {
        en: 'to take a u-turn back to the previous page.',
        fr: 'pour faire demi-tour et retourner à la page précédente.',
      }
    : {
        en: 'to return to the Home page.',
        fr: 'pour revenir à la page d’accueil.',
      };

  useEffect(() => {
    if (is404) {
      const requestedPath = window.location.href;
      const referringPagePath = document.referrer || '';
      window.dataLayer.push({
        event: 'e_httpError',
        httpStatusCode: '404',
        referringPagePath,
        requestedPagePath: requestedPath,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Honda Power Equipment</title>
        <style type="text/css">
          {`
          .errorSection {
            margin: 60px 0;
          }
          .errorSection hr {
            margin: 30px 0;
            height: 2px;
            width: 100%;
            background: #d8d8d8;
          }
          .errorSection h1,
          .errorSection h6 {
            margin: 0 0 15px 0;
          }
          .errorSection h1 {
            font-size: 32px;
            font-family: "Gotham-Book", "Gotham-Medium", -apple-system, BlinkMacSystemFont,
              "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
              "Droid Sans", "Helvetica Neue", "Arial", sans-serif;
            font-weight: normal;
            line-height: 1.2em;
          }
          .errorSection h6 {
            font-family: "Gotham-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
              "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
              "Helvetica Neue", "Arial", sans-serif;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.2em;
            color: #fc1421;
          }
          .errorSection p {
            margin-bottom: 15px;
            font-size: 13px;
            font-family: "Gotham-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
              "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
              "Helvetica Neue", "Arial", sans-serif;
            line-height: 1.6em;
            -webkit-font-smoothing: antialiased;
          }
          .errorSection a {
            text-decoration: none;
            color: #0267d0;
          }
          .contentWrapper {
            margin: 0 auto;
            max-width: 910px;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
          }
          @media only screen and (max-width: 1023px) {
            .contentWrapper {
              width: 90%;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -webkit-flex-direction: column;
              flex-direction: column;
            }
          }
          @media only screen and (min-width: 1024px) {
            .contentWrapper {
              width: 90%;
            }
          }
        `}
        </style>
      </Helmet>

      <section className="errorSection">
        <div className="contentWrapper">
          <div className="errorInfo">
            <div className="errorMessage">
              <h1>ERROR</h1>
              <h6>This page cannot be found.</h6>
              <p>
                Sorry, we didn’t mean to lead you down the wrong track. The current Honda page you are looking for might
                have been removed, had its name changed or is temporarily unavailable.
              </p>
              <p className="errorMessageInfo">
                <a href={linkPath.en}>Click here</a> {copy.en}
              </p>
            </div>
          </div>
          <hr />
          <div className="error-info">
            <div className="error-message">
              <h1>ERREUR</h1>
              <h6>Cette page ne peut être trouvée.</h6>
              <p>
                Désolé, nous n’avions pas l’intention de vous diriger dans la mauvaise direction. La page Honda que vous
                cherchez n’existe peut-être plus, son nom a pu être changé ou elle peut être temporairement hors
                d’usage.
              </p>
              <p className="errorMessageInfo">
                <a href={linkPath.fr}>Cliquez ici</a> {copy.fr}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ApplicationErrorMessage.propTypes = {
  is404: PropTypes.bool,
};

export default ApplicationErrorMessage;
