import { HondaTheme } from '@honda-canada/design-system-react/lib/honda-theme';

const Theme = {
  ...HondaTheme,
  header: {
    desktop: {
      ...HondaTheme.header.desktop,
      height: '85px',
      stickyHeight: '85px',
    },
    mobile: { ...HondaTheme.header.mobile, height: '45px' },
  },
  breakpoints: [HondaTheme.breakpoints[0], '1025px', HondaTheme.breakpoints[2]],
};

export default Theme;
