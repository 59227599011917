/* eslint-disable import/no-named-as-default */

import Hero from '@honda-canada/sitecore-jss-components/lib/components/Hero';
import TrimFilter from '@honda-canada/sitecore-jss-components/lib/components/ModelListFilters';
import AppHero from '@honda-canada/sitecore-jss-components/lib/components/AppHero';
import ModelCard from '@honda-canada/sitecore-jss-components/lib/components/ModelCard';
import Title from '@honda-canada/sitecore-jss-components/lib/components/Title';
import FAQ from '@honda-canada/sitecore-jss-components/lib/components/FAQ';
import CarouselHero from '@honda-canada/sitecore-jss-components/lib/components/CarouselHero';
import SideNav from '@honda-canada/sitecore-jss-components/lib/components/SideNav';
import SideNavScrollIndicator from '@honda-canada/sitecore-jss-components/lib/components/SideNavScrollIndicator';
import ContactUs from '@honda-canada/sitecore-jss-components/lib/components/ContactUs';
import FreeFormText from '@honda-canada/sitecore-jss-components/lib/components/FreeFormText';
import LayoutContainer from '@honda-canada/sitecore-jss-components/lib/components/LayoutContainer';
import PortraitCard from '@honda-canada/sitecore-jss-components/lib/components/PortraitCard';
import LandscapeCard from '@honda-canada/sitecore-jss-components/lib/components/LandscapeCard';
import LandscapeBoxCard from '@honda-canada/sitecore-jss-components/lib/components/LandscapeBoxCard';
import SectionContainer from '@honda-canada/sitecore-jss-components/lib/components/SectionContainer';
import FullWidthCard from '@honda-canada/sitecore-jss-components/lib/components/FullWidthCard';
import TabsContainer from '@honda-canada/sitecore-jss-components/lib/components/TabsContainer';
import Tab from '@honda-canada/sitecore-jss-components/lib/components/Tab';
import DiagonalCard from '@honda-canada/sitecore-jss-components/lib/components/DiagonalCard';
import DiagonalCardContainer from '@honda-canada/sitecore-jss-components/lib/components/DiagonalCardContainer';
import FourColumnLayoutContainer from '@honda-canada/sitecore-jss-components/lib/components/FourColumnLayoutContainer';
import IconCard from '@honda-canada/sitecore-jss-components/lib/components/IconCard';
import HoverCard from '@honda-canada/sitecore-jss-components/lib/components/HoverCard';
import CustomLayoutFactory from '@honda-canada/sitecore-jss-components/lib/components/CustomLayoutFactory';
import SideNavLayout from '@honda-canada/sitecore-jss-components/lib/components/SideNavLayout';
import PlanCard2 from '@honda-canada/sitecore-jss-components/lib/components/PlanCard2';
import SectionNavigation from '@honda-canada/sitecore-jss-components/lib/components/SectionNavigation';
import QuickLink from '@honda-canada/sitecore-jss-components/lib/components/QuickLink';
import UnOrderedList from '@honda-canada/sitecore-jss-components/lib/components/UnOrderedList';
import PackageCard from '@honda-canada/sitecore-jss-components/lib/components/PackageCard';
import Icon from '@honda-canada/sitecore-jss-components/lib/components/Icon';
import Table from '@honda-canada/sitecore-jss-components/lib/components/Table';
import PreOrderBanner from '@honda-canada/sitecore-jss-components/lib/components/PreOrderBanner';
import DropdownToggleContainer from '@honda-canada/sitecore-jss-components/lib/components/DropdownToggleContainer';
import DropdownToggleItem from '@honda-canada/sitecore-jss-components/lib/components/DropdownToggleItem';
import ShowcaseCard from '@honda-canada/sitecore-jss-components/lib/components/ShowcaseCard';
import AnchorContainer from '@honda-canada/sitecore-jss-components/lib/components/AnchorContainer';
import ThreeColumnLayout from '@honda-canada/sitecore-jss-components/lib/components/ThreeColumnLayout';
import AppCard from '@honda-canada/sitecore-jss-components/lib/components/AppCard';
import AppBanner from '@honda-canada/sitecore-jss-components/lib/components/AppBanner';
import TrimSelector from '@honda-canada/sitecore-jss-components/lib/components/TrimSelector';
import CollapsibleContainer from '@honda-canada/sitecore-jss-components/lib/components/CollapsibleContainer';
import LinkPackageCard from '@honda-canada/sitecore-jss-components/lib/components/LinkPackageCard';
import TableStyle2 from '@honda-canada/sitecore-jss-components/lib/components/TableStyle2';
import VideoComponent from '@honda-canada/sitecore-jss-components/lib/components/VideoComponent';
import Slider from '@honda-canada/sitecore-jss-components/lib/components/Slider';
import LandingPageCard from '@honda-canada/sitecore-jss-components/lib/components/LandingPageCard';
import DropdownFilterContainer from '@honda-canada/sitecore-jss-components/lib/components/DropdownFilterContainer';
import FutureVehicleCard from '@honda-canada/sitecore-jss-components/lib/components/FutureVehicleCard';
import CarouselCardsContainer from '@honda-canada/sitecore-jss-components/lib/components/CarouselCardsContainer';
import CarouselWithModalContainer from '@honda-canada/sitecore-jss-components/lib/components/CarouselWithModalContainer';
import VINSearchApp from '@honda-canada/react-subapp-vin-search/lib/components/VINSearchApp';
import DealerLocator from '@honda-canada/react-subapp-dealer-locator/lib/components/DealerLocator';
import PromoBanner from '@honda-canada/sitecore-jss-components/lib/components/PromoBanner';
import Gallery from '@honda-canada/sitecore-jss-components/lib/components/Gallery';

const components = new Map();
components.set('Hero', Hero);
components.set('ModelsTrimsList', TrimFilter);
components.set('AppHero', AppHero);
components.set('Title', Title);
components.set('ModelCard2', ModelCard);
components.set('FaqContent', FAQ);
components.set('CarouselHero', CarouselHero);
components.set('SideNavLayout', SideNavLayout);
components.set('SideNav', SideNav);
components.set('SideNavScrollIndicator', SideNavScrollIndicator);
components.set('ContactUs', ContactUs);
components.set('TabsContainer', TabsContainer);
components.set('Tab', Tab);
components.set('FreeFormText', FreeFormText);
components.set('SingleColumnLayout', LayoutContainer);
components.set('TwoColumnLayout', LayoutContainer);
components.set('ThreeColumnLayout', ThreeColumnLayout);
components.set('FiveColumnLayout', LayoutContainer);
components.set('FourColumnLayout', FourColumnLayoutContainer);
components.set('PortraitCard', PortraitCard);
components.set('LandscapeCard', LandscapeCard);
components.set('LandscapeBoxCard', LandscapeBoxCard);
components.set('SectionContainer', SectionContainer);
components.set('FullWidthCard', FullWidthCard);
components.set('DiagonalCard', DiagonalCard);
components.set('DiagonalCardContainer', DiagonalCardContainer);
components.set('IconCard', IconCard);
components.set('HoverCard', HoverCard);
components.set('PlanCard2', PlanCard2);
components.set('TwoCardUpLayout', CustomLayoutFactory);
components.set('ThreeCellLayout', CustomLayoutFactory);
components.set('FiveCellLayout', CustomLayoutFactory);
components.set('SixCellLayout', CustomLayoutFactory);
components.set('SectionNav', SectionNavigation);
components.set('QuickLink', QuickLink);
components.set('UnOrderedList', UnOrderedList);
components.set('PackageCard', PackageCard);
components.set('Icon', Icon);
components.set('Table', Table);
components.set('PreOrderBanner', PreOrderBanner);
components.set('DropdownToggleContainer', DropdownToggleContainer);
components.set('DropdownToggleItem', DropdownToggleItem);
components.set('ShowcaseCard', ShowcaseCard);
components.set('AnchorContainer', AnchorContainer);
components.set('AppCard', AppCard);
components.set('AppBanner', AppBanner);
components.set('TrimSelector', TrimSelector);
components.set('CollapsibleContainer', CollapsibleContainer);
components.set('LinkPackageCard', LinkPackageCard);
components.set('TableStyle2', TableStyle2);
components.set('VideoComponent', VideoComponent);
components.set('Slider', Slider);
components.set('LandingPageCard', LandingPageCard);
components.set('SiteLogo', () => null); // to prevent 'missing JSS component' error
components.set('DropdownFilterContainer', DropdownFilterContainer);
components.set('FutureVehicleCard', FutureVehicleCard);
components.set('CarouselCardsContainer', CarouselCardsContainer);
components.set('CarouselWithModalContainer', CarouselWithModalContainer);
components.set('VinRecalls', VINSearchApp);
components.set('DealerLocator', DealerLocator);
components.set('PromoBanner', PromoBanner);
components.set('Gallery', Gallery);

export default function componentFactory(componentName) {
  return components.get(componentName);
}
