/* eslint-disable no-underscore-dangle */
import 'es6-promise/auto';
import 'isomorphic-fetch';
import 'core-js/stable';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import isBrowserModern from '@honda-canada/sitecore-jss-common/lib/utils/isBrowserModern';
import i18nInit from '@honda-canada/sitecore-jss-common/lib/utils/i18nInit';
import AppRoot from '@honda-canada/sitecore-jss-common/lib/components/AppRoot';
import ErrorBoundary from '@honda-canada/sitecore-jss-common/lib/components/ErrorBoundary';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@honda-canada/sitecore-jss-components/lib/core/store';
import '@honda-canada/design-system-react/lib/honda-css.css';
import { GoogleMapsApiKeyProvider } from '@honda-canada/experience-api-client';
import ReducersProvider from '@honda-canada/sitecore-jss-components/lib/components/ReducersProvider';
import UserLocationProvider from '@honda-canada/user-location';
import Theme from './Theme';
import componentFactory from './componentFactory';
import ApplicationErrorMessage from './ApplicationErrorMessage';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

let renderFunction = ReactDOM.render;

let initLanguage = 'en';

let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');

if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}

if (__JSS_STATE__) {
  renderFunction = ReactDOM.hydrate;
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

const { config } = __JSS_STATE__;

const App = () => {
  const googleMapsConfig = {
    env: config?.hondaExperienceApiEnv,
    consumerId: config?.hondaExperienceApiConsumerId,
    consumerName: config?.hondaExperienceApiConsumerName,
  };
  const [reloadLocationFromCookies, setReloadLocationFromCookies] = useState(false);

  return (
    <ErrorBoundary errorMessage={<ApplicationErrorMessage />}>
      <ReduxProvider store={store}>
        <HelmetProvider>
          <GoogleMapsApiKeyProvider {...googleMapsConfig}>
            {googleMapsApiKey => (
              <UserLocationProvider
                googleMapsApiKey={googleMapsApiKey}
                forceReload={reloadLocationFromCookies}
                onLocationChanged={() => {
                  setReloadLocationFromCookies(!reloadLocationFromCookies);
                }}
              >
                <AppRoot
                  path={window.location.pathname}
                  Router={BrowserRouter}
                  ReducersProvider={ReducersProvider}
                  ssrState={__JSS_STATE__}
                  config={config}
                  activePlaceholders={['main']}
                  errorMessage={<ApplicationErrorMessage is404 />}
                  theme={Theme}
                  componentFactory={componentFactory}
                />
              </UserLocationProvider>
            )}
          </GoogleMapsApiKeyProvider>
        </HelmetProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

const renderApp = async () => {
  const dictionary = __JSS_STATE__?.viewBag?.dictionary;
  const rootElement = document.getElementById('jss-root');

  await i18nInit(initLanguage, dictionary, config);

  renderFunction(<App />, rootElement);
};

if (isBrowserModern()) {
  renderApp();
} else {
  import('./polyfills').then(renderApp);
}
